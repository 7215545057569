.root {
  display: flex;
}

/* DASHBOARD */

.c-dashboard{
}

.c-dashboard .content{
  /* margin-left: 240px; */
}