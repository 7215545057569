.c-onuinfo__card {
  position: relative;
}

.c-onuinfo__card .MuiCardHeader-root {
  text-transform: capitalize;
}

.c-onuinfo__card.discoveries .MuiCardHeader-root {
  background-color: #2980b9;
}

.c-onuinfo__card.online .MuiCardHeader-root {
  background-color: #2ecc71;
}

.c-onuinfo__card.offline .MuiCardHeader-root {
  background-color: #95a5a6;
}

.c-onuinfo__card.alarms .MuiCardHeader-root {
  background-color: #f1c40f;
}

.c-onuinfo__card__loading {
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
}
